import React, { useContext } from 'react';

import Context, { TOGGLE_BETA_MODAL } from 'context';

type CardProps = {
  title: string;
  price: number | string;
};

const SmallCard: React.FC<CardProps> = ({price, title, children}) => {
  const {dispatch} = useContext(Context);
  const _handleJoinUp = () => dispatch({ type: TOGGLE_BETA_MODAL });
  return (
    <div className="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-xlg shadow-lg md:-ml-4 overflow-hidden">
      <div className="text-lg leading-none h-40 pricing-header rounded-b-5xl text-white font-semibold uppercase py-4 text-center tracking-wide pt-8">
        {title}
        <div className="text-3xl pt-6">${price} / Month</div>
      </div>
      <div className="text-black rounded-lg shadow-lg overflow-hidden">
        <div className="flex flex-wrap mt-3 px-6">
          <ul>
            {children}
          </ul>
        </div>
        <div className="block flex items-center p-8  uppercase">
          <button onClick={_handleJoinUp} className="mt-3 text-lg font-semibold pricing-button w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:bg-gray-700">
            Join Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default SmallCard;