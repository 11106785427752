import React from "react";
import { withNavigation, NavigationActions } from "@react-navigation/core";

type Props = {
  routeName: string;
  navigation: any; // FIXME:
  className: string;
  href: string;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
};

const Link: React.FC<Props> = ({ navigation, routeName, children, className, href }) => {
  const _navigate = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigation.dispatch(NavigationActions.navigate({ routeName }));
  };
  return (
    <a href={href} onClick={_navigate} className={`${className} cursor-pointer`}>
      {children}
    </a>
  );
};

export default withNavigation(Link);
