import React from "react";
import { map } from 'lodash/fp';

import LargeCard from './LargeCard';
import SmallCard from './SmallCard';

type Benefit = {
  icon: string;
  color: string;
  text: string;
}

type PricingCardProps = {
  title: string;
  price: number | string;
  benefits: Array<Benefit>;
  large?: boolean;
};

const PricingCard: React.FC<PricingCardProps> = ({ title, price, benefits, large = false }) => {
  const benefitList = map((benefit: Benefit) => (
    <li key={`${benefit.text}`} className="flex items-center">
      <div className={`rounded-full p-2 fill-current text-${benefit.color}-700`}>
        <i className={`${benefit.icon} fa-2x`}></i>
      </div>
      <span className="text-gray-700 text-lg ml-3">{benefit.text}</span>
    </li>
  ))(benefits);
  return large ? (
    <LargeCard title={title} price={price}>
      {benefitList}
    </LargeCard>
  ) : (
    <SmallCard title={title} price={price}>
      {benefitList}
    </SmallCard>
  )
};

export default PricingCard;