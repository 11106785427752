import React from 'react';

import { Section, ServiceCard } from 'components';

type Props = {
  target: any
}

const Services: React.FC<Props> = ({ target }) => (
  <Section target={target} title="Services" color="white">
    <div className="w-full md:w-2/3 mx-auto sm:flex-row flex-col flex pt-16">
      <div className="w-full md:m-4">
        <ServiceCard icon="fas fa-coins" color="red" title="Multicoins" description="Allows the trading of multiple-coins at once. Limited to the subscription plan chosen." />
        <ServiceCard icon="fas fa-universal-access" color="green" title="Easy Access" description="Sign up is simple and fast. Connect to your Coinbase account in just a few steps." />
      </div>
      <div className="w-full md:m-4">
        <ServiceCard icon="far fa-calendar-alt" color="purple" title="Scheduling" description="Gives you the power to schedule a buy-in as well as once that buy in takes place set the sell-out." />
        <ServiceCard icon="fas fa-shield-alt" color="blue" title="Secure & Reliable" description="Trades over a secure network protocol. Manditory Two-Factor Authentication to keep your access secure." />
      </div>
    </div>
  </Section>
);

export default Services;