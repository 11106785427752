import { createContext } from 'react';

export type InitialDefaultState = {
  showBetaModal: boolean
}

export interface IModalProps {
  store: InitialDefaultState
  dispatch: any
}

export const initialDefaultState = {
  showBetaModal: false
}

export const TOGGLE_BETA_MODAL = 'TOGGLE_BETA_MODAL';

export const reducer = (state: InitialDefaultState = initialDefaultState, action: any): InitialDefaultState => {
  switch (action.type) {
    case TOGGLE_BETA_MODAL:
      return {
        ...state,
        showBetaModal: ! state.showBetaModal
      }
    default:
      return state;
  }
};

export default createContext({} as IModalProps);