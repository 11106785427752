import React from 'react';

import BannerLeft from './BannerLeft';
import BannerRight from './BannerRight';
import HeaderFooter from './HeaderFooter';

const Banner = () => {
  return (
    <section className="relative w-full h-screen">
      <div className="flex flex-col h-auto md:flex-row w-2/3 mx-auto pt-48 md:pt-72 text-center text-white justify-center align-center">
        <BannerLeft />
        <BannerRight />
      </div>
      <HeaderFooter />
    </section>
  );
};

export default Banner;