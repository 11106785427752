import {
  createNavigator,
  SwitchRouter,
} from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";

import Main from 'templates/Main';
import Landing from 'pages/Landing';

const AppNavigator = createNavigator(
  Main,
  SwitchRouter({
    Landing: {
      screen: Landing,
      path: '',
      navigationOptions: {
        title: 'Divvy Brain - Welcome'
      }
    },
    // About: {
    //   screen: About,
    //   path: 'about',
    //   navigationOptions: {
    //     title: 'Divvy Brain - About'
    //   }
    // },
    // Services: {
    //   screen: Services,
    //   path: 'services',
    //   navigationOptions: {
    //     title: 'Divvy Brain - Services'
    //   }
    // },
    // Pricing: {
    //   screen: Pricing,
    //   path: 'pricing',
    //   navigationOptions: {
    //     title: 'Divvy Brain - Pricing'
    //   }
    // },
    // Contact: {
    //   screen: Contact,
    //   path: 'contact',
    //   navigationOptions: {
    //     title: 'Divvy Brain - Contact'
    //   }
    // }
  },{
    initialRoute: 'Landing'
  }),
  {}
);

const App = createBrowserApp(AppNavigator);

export default App;