import React, {useEffect, useState, useContext} from 'react';

import Context, { TOGGLE_BETA_MODAL } from 'context';
import ScrollTo from './ScrollTo';

type Props = {
  refs: any;
};

const Navigation = ({ refs }: Props) => {

  const [scrollBar, setScrollBar] = useState(false);
  const {dispatch} = useContext(Context);

  const menuRef = React.createRef<HTMLDivElement>();
  const _toggleMenu = () => menuRef.current!.classList.toggle("hidden");

  // Toggle the BetaModal to show
  const _handleLogIn = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch({ type: TOGGLE_BETA_MODAL });
  };

  useEffect(() => {
    const scrollListener = (e: any) => {
      setScrollBar(window.scrollY > 200);
    };
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    }
  });

  const logoStyle = scrollBar ? "divvy-brain-logo-alt" : "divvy-brain-logo";
  const scrollBarStyle = scrollBar ? 'md:bg-white md:opacity-100' : '';
  const scrollToStyle = scrollBar
    ? 'text-white hover:text-purple-600 md:text-divvyBlue md:hover:text-purple-600'
    : 'text-white hover:text-purple-600';

  return (
    <nav className={`fixed w-full h-auto top-0 md:text-center py-6 px-6 md:px-64 z-50 bg-purple-400 opacity-75 md:bg-transparent ${scrollBarStyle}`}>
      <div className="md:flex md:items-center">
        <div className="flex items-center">
          <div className="flex flex-shrink-0">
            <ScrollTo top>
              <img className={logoStyle} alt="Divvy Brain" style={{ height: "60px" }} />
            </ScrollTo>
          </div>
          <div className="flex justify-end w-full md:hidden">
            <button onClick={_toggleMenu} className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-black hover:border-black">
              <i className="fas fa-bars"></i>
            </button>
          </div>
        </div>
        <div ref={menuRef} className="block md:w-full md:flex md:flex-grow md:justify-end hidden">
          <div className="md:flex pt-6 md:pt-0">
            <div className="flex">
              <ScrollTo target={refs.services} className={`block mt-4 lg:inline-block lg:mt-0 ${scrollToStyle} md:mr-12`}>
                Services
              </ScrollTo>
            </div>
            <div className="flex">
              <ScrollTo target={refs.pricing} className={`block mt-4 lg:inline-block lg:mt-0 ${scrollToStyle} md:mr-12`}>
                Pricing
              </ScrollTo>
            </div>
            <div className="flex">
              <a onClick={_handleLogIn} href="http://dashboard.divvybrain.com/" className={`block mt-4 lg:inline-block lg:mt-0 ${scrollToStyle}`}>
                <i className="fas fa-sign-in-alt"></i> Log In
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;