import React from 'react';

import { Section, PricingCard } from 'components';

type Props = {
  target: any
}

const Pricing: React.FC<Props> = ({ target }) => (
  <Section target={target} title="Pricing" color="gray-200">
      <p className="italic text-lg text-gray-800 text-center pt-2">*No fees apply, only a monthly subscription cost will be charged.</p>
      <div className="max-w-full md:max-w-6xl mx-auto my-3 md:px-8 py-16">
        <div className="relative block flex flex-col md:flex-row items-center">
          <PricingCard
            title="Hobby-Trader"
            price={30}
            benefits={[
              { icon: "far fa-check-circle", color: 'green', text: "Schedule up to 1 coins" },
              { icon: "far fa-check-circle", color: 'green', text: "500 Monthly Trades" },
              { icon: "far fa-check-circle", color: 'green', text: "Limited Sandbox Access" }
            ]}
          />
          <PricingCard
            title="Day-Trader"
            price={60}
            benefits={[
              { icon: "far fa-check-circle", color: 'green', text: "Schedule up to 3 coins" },
              { icon: "far fa-check-circle", color: 'green', text: "1500 Monthly Trades" },
              { icon: "far fa-check-circle", color: 'green', text: "Sandbox Access" }
            ]}
            large
          />
          <PricingCard
            title="Pro-Trader"
            price={120}
            benefits={[
              { icon: "far fa-check-circle", color: 'green', text: "Schedule up to 5 coins" },
              { icon: "far fa-check-circle", color: 'green', text: "5000 Monthly Trades" },
              { icon: "far fa-check-circle", color: 'green', text: "Sandbox Access" }
            ]}
          />
        </div>
      </div>
  </Section>
);

export default Pricing;