import React, { useContext } from 'react';

import Context, { TOGGLE_BETA_MODAL } from 'context';

const BannerLeft = () => {
  const {dispatch} = useContext(Context);
  const _navigate = () => dispatch({type: TOGGLE_BETA_MODAL});
  return (
    <div className="md:w-1/2 w-full hero-text text-center md:text-left z-50">
      <h2 className="leading-none font-bold text-2xl xs:text-2x1 md:text-5xl lg:6x1 uppercase">
        Get the ultimate profit from <span className="text-purple-600">crypto-currency</span> trading
      </h2>
      <p className="my-6 md:my-12">
        Register, choose your plan, connect your Coinbase account, and start scheduling your next trades.
      </p>
      <button
        onClick={_navigate}
        className="px-16 rounded-full bg-white text-divvyBlue hover:bg-purple-600 hover:text-white p-4 uppercase"
      >
        Sign Up!
      </button>
    </div>
  );
};

export default BannerLeft;
