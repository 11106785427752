import React, { useReducer } from "react";
import { SceneView } from "@react-navigation/core";

import Context, { reducer, initialDefaultState } from "context";

type Props = {
  descriptors: any;
  navigation: any;
};

const Main = ({ descriptors, navigation }: Props) => {
  const [store, dispatch] = useReducer(reducer, initialDefaultState)
  const activeKey = navigation.state.routes[navigation.state.index].key;
  const descriptor = descriptors[activeKey];
  return (
    <Context.Provider value={{ store, dispatch }}>
      <SceneView component={descriptor.getComponent()} navigation={descriptor.navigation} />
    </Context.Provider>
  );
};

export default Main;
