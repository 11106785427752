import React, { useContext } from "react";

import Context, { TOGGLE_BETA_MODAL } from 'context';
import { ActionButton } from 'components';

const BetaModal: React.FC = () => {
  const {dispatch} = useContext(Context);
  // const _handleSignUp = async () => {
  //   await fetch('https://api.divvybrain.com/v1/beta-newsletter', {
  //     method: 'POST',

  //   });
  // }
  const _handleCancel = () => dispatch({ type: TOGGLE_BETA_MODAL });
  return (
    <div className="fixed top-0 h-screen w-screen z-1000 text-gray-800">
      <div className="flex flex-col items-center flex-1 h-full justify-center px-4 sm:px-0">
        <div className="flex rounded-lg shadow-lg w-full h-92 sm:w-3/4 lg:w-1/4 bg-white sm:mx-0">
          <div className="flex flex-col w-full p-4">
            <div className="flex flex-col flex-1 justify-center mb-8">
              <h1 className="text-4xl text-center font-thin">Currently closed to public access!</h1>
              <div className="w-full mt-4">
                <form className="form-horizontal w-3/4 mx-auto" method="POST" action="#">
                  {/* <div className="flex flex-col mt-4">
                    <input
                      id="email"
                      type="text"
                      className="flex-grow h-8 px-2 border rounded border-grey-400"
                      name="email"
                      value=""
                      placeholder="Email"
                    />
                  </div> */}
                  {/* <ActionButton bgColor="blue" textColor="white" handleOnClick={_handleSignUp}>
                    <i className="far fa-paper-plane"></i> Sign Up
                  </ActionButton> */}
                  <ActionButton bgColor="red" textColor="white" handleOnClick={_handleCancel}>
                    <i className="fas fa-times"></i> Close
                  </ActionButton>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetaModal;
