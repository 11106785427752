import React from 'react';

type Props = {
  bgColor: string
  textColor: string
  handleOnClick: any
}

const ActionButton: React.FC<Props> = ({bgColor, textColor, handleOnClick, children}) => (
  <div className="flex flex-col mt-8">
    <button
      onClick={handleOnClick}
      type="submit"
      className={`bg-${bgColor}-500 hover:bg-${bgColor}-700 text-${textColor} text-sm font-semibold py-2 px-4 rounded`}
    >
      {children}
    </button>
  </div>
);

export default ActionButton;