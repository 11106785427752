import React from 'react';

import Particle from '../Particle';
import Navigation from './Navigation';
import Banner from './Banner';

const Header = ({ refs }: any) => {
  return (
    <div className="relative mt-0 py-0 h-screen hero">
      <Navigation refs={refs} />
      <Particle className="absolute top-0 z-0"/>
      <Banner />
    </div>
  );
};

export default Header;