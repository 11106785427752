import React from 'react';

type Props = {
  top?: boolean;
  target?: any;
  className?: string;
}

const ScrollTo: React.FC<Props> = ({ top, target, className, children }) => {
  const _scrollTo = (top) ? () => window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  }) : () => target.current.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  });
  return (
    <a href="js:void();" onClick={_scrollTo} className={className}>
      {children}
    </a>
  );
};

export default ScrollTo;