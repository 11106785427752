import React from 'react';

const HeaderFooter = () => (
  <svg className="absolute bottom-0 h-128 w-full" viewBox="0 0 100 30" version="1.1" preserveAspectRatio="none">
    <path
      id="path6"
      d="m-100 1.041s61.625-4.5064 99.75 5.2333 70.594 15.391 124.5 16.312c55.25 0.9437 75.75-8.8513 75.75-8.8513v16.266h-300v-28.959z"
      style={{ fill: "#fff", fillOpacity: 0.2 }}
      className="layer5"
    ></path>
    <path
      id="path8"
      d="m-100 30h300v-6.6791s-16.526 2.7112-62.25 2.3702c-58.5-0.436-97.875-12.245-153.75-15.599-55.875-3.3549-84-0.2745-84-0.2745v20.182z"
      style={{ fill: "#fff", fillOpacity: 0.2 }}
      className="layer4"
    ></path>
    <path
      id="path10"
      d="m200 16.232s-24.625-5.6378-84.5-3.7495c-59.875 1.8882-74.962 15.943-144 16.562-50.75 0.455-71.5-3.7697-71.5-3.7697v4.7252h300v-13.768z"
      style={{ fill: "#fff", fillOpacity: 0.2 }}
      className="layer3"
    ></path>
    <path
      id="path12"
      d="m200 1.041s-61.625-4.5064-99.75 5.2333-70.594 15.391-124.5 16.311c-55.25 0.9437-75.75-8.8513-75.75-8.8513v16.266h300v-28.959z"
      style={{ fill: "#fff", fillOpacity: 0.2 }}
      className="layer2"
    ></path>
    <path
      id="path14"
      d="m-100 17.511s29.006-2.6495 75-0.6876c60.25 2.5701 81.25 11.545 150.25 11.912 55.721 0.2965 74.75-5.6414 74.75-5.6414v6.906h-300v-12.489z"
      style={{ fill: "#fff" }}
      className="layer1"
    ></path>
  </svg>
);

export default HeaderFooter;