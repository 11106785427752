import React, { useRef, useContext } from "react";

import Context from 'context';
import { Services, Pricing } from './partials';
import { Header, Footer, BetaModal } from "components";

const Landing: React.FC = () => {
  const {store} = useContext(Context);
  const refs = {
    services: useRef(),
    pricing: useRef()
  };
  return (
    <React.Fragment>
      <Header refs={refs} />
      <Services target={refs.services} />
      <Pricing target={refs.pricing} />
      <Footer />
      { store.showBetaModal ? <BetaModal /> : null }
    </React.Fragment>
  );
};

export default Landing;
