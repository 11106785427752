import React, { useContext } from 'react';

import Context, { TOGGLE_BETA_MODAL } from 'context';

type CardProps = {
  title: string;
  price: number | string;
};

const LargeCard: React.FC<CardProps> = ({ title, price, children }) => {
  const {dispatch} = useContext(Context);
  const _handleJoinUp = () => dispatch({ type: TOGGLE_BETA_MODAL });
  return (
    <div className="w-full max-w-md sm:w-2/3 lg:w-1/3 sm:my-5 my-8 relative z-10 bg-white rounded-xlg shadow-lg overflow-hidden mx-12">
      <div className="text-lg leading-none h-40 pricing-header rounded-b-5xl text-white font-semibold uppercase py-4 text-center tracking-wide pt-8">
        {title}
        <div className="text-3xl pt-6">${price} / Month</div>
      </div>
      <div className="flex pl-12 justify-start sm:justify-start mt-12">
        <ul>
          {children}
        </ul>
      </div>
      <div className="block flex items-center p-8  uppercase">
        <button onClick={_handleJoinUp} className="mt-3 text-lg font-semibold pricing-button w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:bg-gray-700">
          Join Now
        </button>
      </div>
    </div>
  );
};

export default LargeCard;