import React from 'react';

const Footer: React.FC = () => {
  const year = (new Date()).getFullYear();
  return (
    <footer className="flex w-full mx-auto py-4 bg-divvyBlue ext-black text-white">
      <div className="w-full text-center text-xs md:text-lg">Copyright © {year} DivvyBrain.com All Rights Reserved</div>
    </footer>
  );
};

export default Footer;