import React from "react";

type Props = {
  icon: string;
  color: string;
  title: string;
  description: string;
};

const ServiceCard: React.FC<Props> = ({icon, color, title, description}) => (
  <div className="w-full sm:mb-0 mb-16 shadow-lg rounded-lg p-6 md:my-4">
    <p className={`flex justify-center items-center rounded-full w-24 h-24 mx-auto mb-2 border-2 border-${color}-400 justify-center items-center`}>
      <i className={`${icon} fa-2x text-${color}-400`}></i>
    </p>
    <p className="font-bold text-center text-xl py-4">{title}</p>
    <p className="italic text-center">{description}</p>
  </div>
);

export default ServiceCard;